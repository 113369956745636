<template>
  <div>
    <div class="container w-full mx-auto py-10">
      <div class="mb-5">
        <div v-if="!site" class="bg-gray-800 p-10 text-white rounded">
          <div class="flex items-start">
            <div class="w-4/5">
              <h1 class="font-bold text-2xl mb-4">
                {{ $route.params.domain }}
              </h1>
            </div>
          </div>
        </div>

        <div v-if="site">
          <div class="bg-gray-800 p-4 md:p-10 text-white rounded">
            <div class="flex items-center">
              <div class="w-1/2 md:w-4/5">
                <div class="">
                  <h1 class="font-bold text-2xl">
                    <div
                      v-if="site.icon"
                      class="inline-block w-14 align-middle mr-2"
                    >
                      <img
                        :src="root + site.icon"
                        class="max-w-full max-h-full rounded"
                      />
                    </div>
                    {{ site.domain }}
                  </h1>
                </div>
              </div>
              <div class="w-1/2 md:w-1/5">
                <div class="text-right">
                  <router-link
                    :to="
                      $i18nRoute({
                        name: 'site',
                        params: { domain: site.domain },
                      })
                    "
                    class="
                      inline-block
                      uppercase
                      text-webmoney
                      bg-white
                      px-5
                      py-2
                      rounded
                      hover:bg-blue-400 hover:text-white
                      shadow
                      text-sm
                      ml-3
                    "
                    >{{ $t("site.to_site_page") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="site" class="mb-3 px-4 md:px-0">
        <div class="">
          <h3
            class="
              font-bold
              px-4
              md:px-0
              uppercase
              text-lg text-gray-700
              dark:text-white
              mb-5
            "
          >
            {{ $t("shared.feedbacks") }}
          </h3>

          <Feedback
            v-for="feedback in feedbacks"
            :key="feedback.id"
            v-bind:feedback="feedback"
            :showSite="false"
          />

          <div class="text-center py-16" v-if="feedbacks.length <= 0">
            <inbox-icon
              size="4x"
              class="block m-auto text-gray-300"
            ></inbox-icon>
            <p class="text-gray-400">{{ $t("site.no_feedbacks") }}</p>
          </div>

          <button
            type="button"
            v-if="showMoreAvailable"
            @click="getFeedbacks"
            class="
              inline-block
              uppercase
              bg-webmoney
              text-white
              px-5
              py-2
              rounded
              hover:bg-blue-400 hover:text-white
              shadow
              text-sm
              my-4
            "
          >
            {{ $t("shared.more_feedbacks") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from "@/components/Feedback.vue";
import sites from "@/api/sites";
import feedbacks from "@/api/feedbacks";
import { InboxIcon } from "vue-feather-icons";
export default {
  components: {
    Feedback,
    InboxIcon,
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      site: null,
      feedbacks: [],
      page: 1,
      showMoreAvailable: true,
    };
  },
  created() {
    this.getSite();
    // this.getFeedbacks(1);
  },
  watch: {
    $route: ["getSite"],
    site: [
      function () {
        this.page = 1;
        this.feedbacks = [];
        this.getFeedbacks();
      },
    ],
  },
  methods: {
    getSite() {
      const domain = this.$route.params.domain;
      sites.getSiteByDomain(domain).then((data) => {
        this.site = data;
      });
    },
    getFeedbacks() {
      feedbacks.getFeedbacksBySite(this.site.id, this.page).then((data) => {
        this.feedbacks = [...this.feedbacks, ...data];
        this.page++;
        if (data.length < 10) {
          this.showMoreAvailable = false;
        }
      });
    },
  },
};
</script>